<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      :rules="rules"
      small="size"
      label-width="200px"
    >
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="互联网医疗机构编码:" prop="orgCode">
            <el-input
              v-model="form.orgCode"
              v-check-permission
              placeholder="请输入互联网医疗机构编码"
              :disabled="isEdit"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构登记号编码:" prop="orgRegNo">
            <el-input
              v-model="form.orgRegNo"
              v-check-permission
              placeholder="请输入机构登记号编码"
              :disabled="isEdit"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="互联网医疗机构名称:" prop="orgName">
            <el-input
              v-model="form.orgName"
              v-check-permission
              placeholder="请输入医疗机构名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始日期:">
            <el-date-picker
              v-model="form.dateStart"
              style="width: 90%"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择开始日期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="通信地址:">
            <el-input
              v-model="form.orgAddress"
              v-check-permission
              placeholder="请输入通信地址"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="截至日期:">
            <el-date-picker
              v-model="form.dateEnd"
              value-format="yyyy-MM-dd"
              type="date"
              style="width: 90%"
              placeholder="选择截至日期"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="联系人姓名:">
            <el-input
              v-model="form.orgContactName"
              v-check-permission
              placeholder="请输入联系人姓名"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经济属性:">
            <el-select v-model="form.economicAttribute">
              <el-option
                v-for="item in orgList"
                :key="item.value"
                :value="item.text"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="联系电话:" prop="orgTel">
            <el-input
              v-model="form.orgTel"
              v-check-permission
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构类别:">
            <el-select v-model="form.orgCategory">
              <el-option
                v-for="item in orgTypeList"
                :key="item.value"
                :value="item.text"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="法人代表:">
            <el-input
              v-model="form.orgPrincipaName"
              v-check-permission
              placeholder="请输入法人代表"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构级别:">
            <el-select v-model="form.orgLevel">
              <el-option
                v-for="item in orgLevelList"
                :key="item.value"
                :value="item.text"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="平均开放床位:" prop="avgActualBeds">
            <el-input
              v-model.number="form.avgActualBeds"
              v-check-permission
              placeholder="请输入数字"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构等次:">
            <el-select v-model="form.orgGrade">
              <el-option
                v-for="item in orgRankList"
                :key="item.value"
                :value="item.text"
                :label="item.text"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="10">
          <el-form-item label="诊疗科目列表:">
            <el-input
              v-model="form.deptCommentList"
              v-check-permission
              placeholder="请输入诊疗科目列表"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编制床位:" prop="authBeds">
            <el-input
              v-model.number="form.authBeds"
              v-check-permission
              placeholder="请输入数字"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="imgType">
        <div v-for="item in imgNameList" :key="item.name" class="a">
          <div class="labelWidth">{{ item.name }}:</div>
          <do-upload
            ref="doupload"
            :action="''"
            :show-file-list="false"
            :file-list="fileList1"
            @done="(vals) => ondone(vals, item.imgType)"
          >
            <el-image
              v-if="item.photoUrl"
              :src="item.photoUrl"
              class="avatar"
              fit="scale-down"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </do-upload>
        </div>
      </div>
      <el-row>
        <el-col :span="1"> &nbsp; </el-col>
        <el-col :span="22">
          <div v-for="item in imageTypeList" :key="item.imgType">
            <el-form-item :label="item.name + ':'">
              <div class="hainanOrg-flex">
                <template v-if="item.photoUrl">
                  <div
                    v-for="(item1, index) in item.photoUrl.split(',')"
                    :key="index"
                  >
                    <div>
                      <el-image
                        v-if="item1"
                        style="width: 148px; height: 148px; margin-right: 10px"
                        :src="item1"
                        fit="scale-down"
                        :preview-src-list="
                          !item.photoUrl ? [] : item.photoUrl.split(',')
                        "
                        @click="solveRefresh()"
                      />
                      <div v-if="item1" style="text-align: center">
                        <el-button
                          type="text"
                          @click="goDeleteImage(index, item.imgType)"
                        >
                          删除
                        </el-button>
                      </div>
                    </div>
                  </div>
                </template>
                <template>
                  <do-upload
                    ref="doupload"
                    :action="''"
                    list-type="picture-card"
                    :show-file-list="false"
                    :file-list="fileList1"
                    @done="(vals) => ondone(vals, item.imgType)"
                  >
                    <i slot="default" class="el-icon-plus" />
                  </do-upload>
                </template>
              </div>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

      <div class="btn">
        <el-button type="primary" @click="saveData()"> 保存 </el-button>
        <!-- <el-button
            type="primary"
            @click="saveData()"
          >
            同步监管部门
          </el-button> -->
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { codeRule, checkPhone } from '@/utils/verificationRule.js';
import DoUpload from '@/components/upload';
import {
  orgList,
  orgTypeList,
  orgLevelList,
  orgRankList,
} from '@/utils/component/publicList';
export default {
  name: 'ServiceManageList',
  components: {
    DoUpload,
  },
  data() {
    return {
      imgNameList: [
        {
          name: '互联网医院备案-电子签章',
          imgType: 'recordSign',
          photoUrl: '',
        },
        {
          name: '互联网医院备案-网络拓扑图',
          imgType: 'recordNet',
          photoUrl: '',
        },
        {
          name: '互联网医院备案-可行性报告',
          imgType: 'recordReport',
          photoUrl: '',
        },
        {
          name: '互联网医院执业许可证',
          imgType: 'practicingLicense',
          photoUrl: '',
        },
      ],
      imageTypeList: [
        {
          name: '互联网医院备案-医疗机构规章制度列',
          imgType: 'regulationsList',
          photoUrl: '',
        },
        { name: '资质文件列表', imgType: 'qualificationList', photoUrl: '' },
      ],
      orgList: orgList, // 经济属性
      checkPhone: checkPhone, // 电话号码校验
      orgTypeList: orgTypeList, // 机构类别
      orgLevelList: orgLevelList, // 机构级别
      orgRankList: orgRankList, // 机构等级
      isAdd: false, // 新增还是编辑
      isEdit: false, // 新增后机构编码和登记号不可更改
      fileList1: [],
      form: {
        id: '',
        orgName: '',
        orgCode: '',
        orgRegNo: '',
        dateStart: '',
        dateEnd: '',
        orgAddress: '',
        orgContactName: '',
        economicAttribute: '',
        orgTel: '',
        orgCategory: '',
        orgPrincipaName: '',
        orgLevel: '',
        avgActualBeds: '',
        orgGrade: '',
        deptCommentList: '',
        authBeds: '',
        fileList: '',
        hosDigitalSign: '',
        recordTopology: '',
        recordFeasibility: '',
        recordProve: '',
        recordRulesList: '',
      },
      rules: {
        orgRegNo: [{ required: false, validator: codeRule, trigger: 'blur' }],
        orgCode: [{ required: false, validator: codeRule, trigger: 'blur' }],
        orgName: [
          {
            required: false,
            message: '请输入互联网医院机构名称',
            trigger: 'blur',
          },
        ],
        avgActualBeds: [
          { required: false, message: '请输入数字', trigger: 'blur' },
          { type: 'number', message: '只能为数字', trigger: 'blur' },
        ],
        authBeds: [
          { required: false, message: '请输入数字', trigger: 'blur' },
          { type: 'number', message: '只能为数字', trigger: 'blur' },
        ],
        orgTel: [{ required: false, validator: checkPhone, trigger: 'blur' }],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getOrganList();
  },
  methods: {
    ...mapActions('basicDictionary', ['organPageQuery', 'organEdit']),
    // 头像上传回调
    ondone(vals, type) {
      console.log(vals, type, '上传图片');
      const len = vals.length;
      if (len && len < 10) {
        switch (type) {
          case 'recordSign':
            this.form.hosDigitalSign = vals[len - 1];
            break;
          case 'recordNet':
            this.form.recordTopology = vals[len - 1];
            break;
          case 'recordReport':
            this.form.recordFeasibility = vals[len - 1];
            break;
          case 'practicingLicense':
            this.form.recordProve = vals[len - 1];
            break;
          case 'regulationsList':
            this.form.recordRulesList = vals + ',';
            break;
          case 'qualificationList':
            this.form.fileList = vals + ',';
            break;
          default:
            break;
        }
        console.log(this.form);
        this.resetImg(this.form);
      }
    },
    // 重置图片内容
    resetImg(form) {
      this.imgNameList = [
        {
          name: '互联网医院备案-电子签章',
          imgType: 'recordSign',
          photoUrl: form.hosDigitalSign,
        },
        {
          name: '互联网医院备案-网络拓扑图',
          imgType: 'recordNet',
          photoUrl: form.recordTopology,
        },
        {
          name: '互联网医院备案-可行性报告',
          imgType: 'recordReport',
          photoUrl: form.recordFeasibility,
        },
        {
          name: '互联网医院执业许可证',
          imgType: 'practicingLicense',
          photoUrl: form.recordProve,
        },
      ];
      this.imageTypeList = [
        {
          name: '互联网医院备案-医疗机构规章制度列',
          imgType: 'regulationsList',
          photoUrl: form.recordRulesList,
        },
        {
          name: '资质文件列表',
          imgType: 'qualificationList',
          photoUrl: form.fileList,
        },
      ];
      console.log(this.imgNameList, 'zhonggg ');
    },
    // 分页查询用户套餐列表
    getOrganList() {
      const param = {
        code: 'WEBH071',
      };
      this.$api.queryRegulatoryOrganDetail(param).then((res) => {
        if (!res.data || res.data === {}) {
          this.isAdd = true;
        } else {
          this.isAdd = false;
          this.form = res.data;
        }
        this.resetImg(this.form);
      });
    },
    // 新增医院机构
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const param = {
            ...JSON.parse(JSON.stringify(this.form)),
          };
          this.$api.regulatoryOrganEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: this.isAdd
                  ? '新增互联网机构成功'
                  : '编辑互联网机构成功',
              });
              this.isEdit = true;
              this.getOrganList();
            }
            // this.$refs['form1'].resetFields();
          });
        } else {
          return false;
        }
      });
    },
    // 删除图片
    goDeleteImage(index, imgType) {
      this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          switch (imgType) {
            case 'regulationsList':
              var list = this.form.recordRulesList.split(',');
              list.splice(index, 1);
              this.$set(this.form, 'recordRulesList', list.join(','));
              this.resetImg(this.form);
              break;
            case 'qualificationList':
              var list1 = this.form.fileList.split(',');
              list1.splice(index, 1);
              this.$set(this.form, 'fileList', list1.join(','));
              this.resetImg(this.form);
              break;
            default:
              break;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  margin-right: 20px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  background-color: #f5f7fa;
}
.imgType {
  display: flex;
  margin-left: 18px;
  flex-wrap: wrap;
}
.a {
  display: flex;
  width: 37%;
  margin-bottom: 20px;
  justify-content: center;
  margin-right: 50px;
}
.labelWidth {
  width: 180px;
}
.btn {
  margin-left: 250px;
}
.hainanOrg-flex {
  display: flex;
}
</style>
